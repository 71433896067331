$(document).ready(function(){
  
  $(window).scroll(function() {
    $(".slideanim").each(function(){
      var pos = $(this).offset().top;

      var winTop = $(window).scrollTop();
        if (pos < winTop + 600) {
          $(this).addClass("slide");
        }
    });
  });

  
});

$(function () {
    var hash = $.trim(window.location.hash); 
    if (hash){
        $('.panel a[href$="' + hash + '"]').trigger('click');
    $('.nav-tabs a[href$="' + hash + '"]').trigger('click');  

    var scroll_offset = (hash.indexOf('_tab')==-1) ? 1 : -250;
    var scroll_time = (hash.indexOf('_tab')==-1) ? 1 : 0;

    setTimeout(function() {
    $('html, body').animate({
       scrollTop: $(hash).offset().top+scroll_offset
    }, scroll_time)}, 150) ; 
    document.location.href= hash;
}

});